<template>
  <app-dialog
    class="app-lp-try-dialog"
    :is-displayed-close-btn="true"
    @close="closeModal"
  >
    <div class="body">
      <div class="heading">
        <h3 class="title">
          HR OnBoardのご利用には<br />engageアカウントが必要です
        </h3>
      </div>
      <label class="here">engageアカウントをお持ちの方はこちら</label>
      <nuxt-link
        :href="$t('uri.loginOnboard')"
        target="_blank"
        rel="noopener noreferrer"
        class="registration"
      >
        <app-lp-button
          class="button"
          variant="primary"
          :disabled="!hasAgreedToTerms"
          >HR OnBoardを無料で試す</app-lp-button
        >
      </nuxt-link>
      <app-checkbox
        v-model="hasAgreedToTerms"
        class="checkbox"
      >
        <nuxt-link
          to="/terms"
          target="_blank"
          class="link"
          >利用規約</nuxt-link
        >
        と
        <nuxt-link
          to="/terms#privacy"
          target="_blank"
          class="link"
          >プライバシーポリシー</nuxt-link
        >
        に同意します。
      </app-checkbox>
      <label class="here">engageアカウントをお持ちでない方はこちら</label>
      <a
        :href="$t('uri.makeEngage')"
        target="_blank"
        rel="noopener noreferrer"
        class="account"
      >
        <app-lp-button
          class="button"
          variant="bluecircle"
          >engageアカウントを作成する</app-lp-button
        >
      </a>
      <a
        :href="$t('uri.contact')"
        target="_blank"
        rel="noopener noreferrer"
        class="link"
      >
        お問い合わせ
      </a>
    </div>
  </app-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'

interface Emits {
  (e: 'click'): void
}

const emit = defineEmits<Emits>()

const hasAgreedToTerms = ref(true)

const closeModal: () => void = () => {
  hasAgreedToTerms.value = true
  emit('click')
}
</script>

<style lang="scss" scoped>
.app-lp-try-dialog {
  color: $black-color;

  > .dialog {
    > .body {
      width: 380px;
      box-sizing: border-box;
      padding: 2rem;
      text-align: center;

      > .heading {
        margin-top: 32px;
        font-size: $font-size-18;
      }

      > .link {
        display: block;
        margin: 30px 0 20px;
        font-size: $font-size-small;
        color: $primary-color;
      }

      > .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: $font-size-small;

        > .link {
          color: $primary-color;
        }
      }

      > .here {
        display: block;
        margin-top: 40px;
        margin-bottom: 14px;
        font-size: $font-size-smaller;
        color: $gray-8;
        text-align: center;
      }

      > .registration,
      > .account {
        > .button {
          width: 300px;
          height: 54px;
        }
      }
    }
  }
}
</style>
