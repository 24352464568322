<template>
  <button
    class="app-lp-button"
    :class="{
      '-default': variant === 'default',
      '-primary': variant === 'primary',
      '-secondary': variant === 'secondary',
      '-white': variant === 'white',
      '-blue': variant === 'blue',
      '-transparent': variant === 'transparent',
      '-bluecircle': variant === 'bluecircle',
      '-bluegrad': variant === 'bluegrad',
    }"
    v-bind="$attrs"
    @click="click"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
export type AppLpButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'white'
  | 'blue'
  | 'transparent'
  | 'bluecircle'
  | 'bluegrad'
  | 'bluegrad'

interface Props {
  variant?: AppLpButtonVariant
}

interface Emits {
  (e: 'click'): void
}

withDefaults(defineProps<Props>(), {
  variant: 'default',
})

const emit = defineEmits<Emits>()

const click: () => void = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
.app-lp-button {
  position: relative;
  padding: 5px 34px 7px 21px;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: $lh-24;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 30px;
  outline: none;

  &:hover {
    opacity: 0.8;
  }

  &.-default {
    color: $white-color;
    background-color: $lp-color-20;
    border-color: $white-color;
  }

  &.-primary {
    padding: 10px 36px 10px 25px;
    font-size: $font-size-16;
    color: $white-color;
    background: $lp-color-23;
    border-color: $white-color;
    box-shadow: $box-shadow;
  }

  &.-secondary {
    font-size: $font-size-normal;
    color: $primary-color;
    background: $white-color;
    box-shadow: $box-shadow;
  }

  &.-white {
    color: $lp-color-16;
    background-color: $white-color;
    border-color: $white-color;
  }

  &.-blue {
    color: $white-color;
    background-color: $primary-color;
    border-color: $white-color;
  }

  &.-transparent {
    color: $primary-color;
    background-color: transparent;
    border-color: $primary-color;
  }

  &.-bluecircle {
    font-size: $font-size-normal;
    color: $primary-color;
    background-color: $white-color;
    border: solid 1px $primary-color;
  }

  &.-bluegrad {
    font-size: $font-size-16;
    color: $white-color;
    background: $lp-color-24;
    border-color: $white-color;
    box-shadow: $box-shadow;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    content: '';
    border-style: solid;
    border-width: 0;
    border-top-width: 2px;
    border-right-width: 2px;
    transform: rotate(45deg);

    @include tablet {
      top: 50%;
      width: 8px;
      height: 8px;
    }
  }

  &:disabled {
    color: $white-color;
    cursor: default;
    background: $gray-9;

    &:hover {
      color: $white-color;
      background: $gray-9;
    }
  }
}
</style>
